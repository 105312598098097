<template>
  <div class="icon-bg">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6569 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z"
        fill="#A7A9C0"
      />
      <path
        opacity="0.3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.98505 18V13C1.98505 11.8954 2.88048 11 3.98505 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10078L2.85693 21.1905C2.53893 21.4949 2.01121 21.2695 2.01121 20.8293V18.3243C1.994 18.2187 1.98505 18.1104 1.98505 18ZM6 14.5C6 14.2239 6.22386 14 6.5 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.5C6.22386 15 6 14.7761 6 14.5ZM9.5 16C9.22386 16 9 16.2239 9 16.5C9 16.7761 9.22386 17 9.5 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.5Z"
        fill="#A7A9C0"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconMessenger",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;

      svg {
        path {
          fill: #5551f9;
        }
      }
    }
  }
  &:active {
    background: transparent;
    svg {
      path {
        fill: #5551f9;
      }
    }
  }
}
</style>
